export const shortenText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  } else {
    return text;
  }
};

export const generatePointBackgroundColors = (amount, slideValue) => {
  return Array.from({ length: amount }, (_, i) =>
    i < slideValue ? '#E65634' : '#FFFFFF'
  );
};
