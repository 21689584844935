import React from 'react';
import PropTypes from 'prop-types';

import { BOTH, BUY, SELL } from '../../../constants/pool.constants';
import { RedPanelSVG } from '../../../images/SVGElements/RedPanel';
import WaxImg from '../../../images/wax_logo2.png';
import NFTImg from '../../../images/NFT.png';
import MiddleImg from '../../../images/Icon awesome-long-arrow-alt-right.svg';

function FirstStep({ currentStep, setSelectedOption, handleSelectOption }) {
  const SelectOptionItem = ({
    text,
    selectedOption,
    firstImg,
    lastImg,
    underText,
  }) => (
    <div
      className="create-pool-stepper_select-options_item"
      onClick={() => {
        setSelectedOption(selectedOption);
        handleSelectOption(currentStep);
      }}
    >
      <RedPanelSVG />
      <div className="create-pool-stepper_select-options_item_content">
        <h4>{text}</h4>
        <div className="img-container">
          <div className="first-img">
            <img src={firstImg} alt="" />
          </div>
          <div className="middle-img">
            <img src={MiddleImg} alt="" />
          </div>
          <div className="last-img">
            <img src={lastImg} alt="" />
          </div>
        </div>
        <p>{underText}</p>
      </div>
    </div>
  );

  const SelectOptionItemBoth = ({
    text,
    selectedOption,
    firstImg,
    lastImg,
    underText,
  }) => (
    <div
      className="create-pool-stepper_select-options_item"
      onClick={() => {
        setSelectedOption(selectedOption);
        handleSelectOption(currentStep);
      }}
    >
      <RedPanelSVG />
      <div className="create-pool-stepper_select-options_item_content">
        <h4>{text}</h4>
        <div className="img-container">
          <div className="first-img">
            <img src={firstImg} alt="" />
          </div>
          <div className="last-img">
            <img src={lastImg} alt="" />
          </div>
        </div>
        <p>{underText}</p>
      </div>
    </div>
  );

  return (
    <div
      className={`create-pool-stepper-first ${
        currentStep !== 1 ? 'hidden' : ''
      }`}
    >
      <SelectOptionItem
        text="Buy NFTs with tokens"
        selectedOption={BUY}
        firstImg={WaxImg}
        lastImg={NFTImg}
        underText={
          'You will deposit tokens and receive NFTs as people swap their NFTs for your deposited tokens.'
        }
      />
      <SelectOptionItem
        text="Sell NFTs with tokens"
        selectedOption={SELL}
        firstImg={NFTImg}
        lastImg={WaxImg}
        underText={
          'You will deposit NFTs and receive tokens as people swap their tokens for your deposited NFTs.'
        }
      />
      <SelectOptionItemBoth
        text="Do both and earn trading fees"
        firstImg={NFTImg}
        lastImg={WaxImg}
        underText={
          'You will deposit both NFTs and tokens and earn trading fees as people buy or sell NFTs using your pool.'
        }
        selectedOption={BOTH}
      />
    </div>
  );
}

FirstStep.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  handleSelectOption: PropTypes.func.isRequired,
};

export default FirstStep;
