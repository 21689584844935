import React from 'react';
import Rodal from 'rodal';
import { Checkbox } from '@mui/material';

import OrangeBorderButton from '../../OrangeBorderButton/OrangeBorderButton';
import OrangeButton from '../../OrangeButton/OrangeButton';

import './RodalCreatePool.scss';
import { CloseIconRedSVG } from '../../../images/SVGElements/CloseAddedCollectionIcon';
import { BOTH, BUY, SELL } from '../../../constants/pool.constants';

import ClearIcon from '../../../images/close.svg';

import './rodal.css';

function RodalCreatePool({
  visible,
  hide,
  handlerOpenList,
  selectedCollection,
  openList,
  setSearchCollectionValue,
  allCollections,
  handleSelectCollection,
  handlerOpenListForSchema,
  selectedSchema,
  openListForSchema,
  currentSchema,
  handleSelectSchema,
  selectedTemplate,
  openListForTemplate,
  currentTemplate,
  handlerOpenListForTemplate,
  handleSelectTemplate,
  handleAdd,
  addedCollections,
  deleteAddedCollection,
  selectedOption,
  myNFTs,
  setSelectedItemFromSell,
  selectedItemFromSell,
  allUserCollections,

  selectedCollectionForSellPool,
  setSelectedCollectionForSellPool,
  selectedSchemaForSellPool,
  setSelectedSchemaForSellPool,
  openListForSellSchema,
  currentSchemaForSell,
  handleSelectCollectionForSellPool,
  handlerSelectedSchemaForSell,
  handlerOpenListForSellSchema,
}) {
  const handlerClickItem = (item) => {
    // Перевіряємо чи існує вже item з таким asset_id
    const index = selectedItemFromSell.findIndex(
      (i) => i.asset_id === item.asset_id
    );

    handleSelectCollection(item.collection.collection_name);
    setSelectedCollectionForSellPool(item.collection);
    if (index === -1) {
      // Якщо не знайдено, додаємо до списку
      setSelectedItemFromSell([...selectedItemFromSell, item]);

      // setSelectedCollection(item.collection.collection_name)
    } else {
      // Якщо знайдено, видаляємо зі списку
      setSelectedItemFromSell(
        selectedItemFromSell.filter((i) => i.asset_id !== item.asset_id)
      );
      // setSelectedCollection(null)
    }
  };

  if (!selectedOption) {
    return false;
  }

  const handleDeleteSelected = () => {
    handleSelectCollection(null);
    setSelectedSchemaForSellPool(null);
  };

  if (selectedOption === BUY) {
    return (
      <Rodal
        className={'rodal-0'}
        visible={visible}
        onClose={hide}
        closeMaskOnClick={false}
      >
        <div className="title">
          <h2>Select NFT</h2>
          <p className={'sub-title'}>
            You can use only collection or select by template and schema
          </p>
        </div>
        <div className="content">
          <div className="content-collections">
            <OrangeBorderButton
              onClick={handlerOpenList}
              width={243}
              height={40}
            >
              {!selectedCollection ? (
                '+ Choose collection'
              ) : (
                <div className={'coll-block'}>
                  {selectedCollection.data.img &&
                  selectedCollection.data.img?.startsWith('Qm') ? (
                    <img
                      src={`https://atomichub-ipfs.com/ipfs/${selectedCollection.data.img}`}
                      alt=""
                    />
                  ) : (
                    <img src={selectedCollection.data.img} alt="" />
                  )}
                  {selectedCollection?.collection_name}
                </div>
              )}
            </OrangeBorderButton>
            {openList && (
              <div className="dropdown-menu">
                <div className="search-block">
                  <input
                    type="text"
                    className={'search-collection'}
                    placeholder={'Search collection'}
                    onChange={(e) => setSearchCollectionValue(e.target.value)}
                  />
                </div>

                {allCollections.map((item, i) => (
                  <div
                    key={i}
                    className="item"
                    onClick={() => handleSelectCollection(item)}
                  >
                    {item.data.img && item.data.img?.startsWith('Qm') ? (
                      <img
                        src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                        alt=""
                      />
                    ) : (
                      <img src={item.data.img} alt="" />
                    )}

                    <div className={'about'}>
                      <p>{item.collection_name}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {selectedCollection && (
            <>
              <div className="content-schema">
                <OrangeBorderButton
                  width={243}
                  height={40}
                  onClick={handlerOpenListForSchema}
                >
                  {!selectedSchema.length
                    ? 'Schema (optional)'
                    : selectedSchema}
                </OrangeBorderButton>
                {openListForSchema && (
                  <div className="dropdown-menu">
                    {currentSchema.map((item) => (
                      <div
                        className="item"
                        onClick={() => handleSelectSchema(item.schema_name)}
                      >
                        <div className="about">
                          <p>{item.schema_name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {selectedSchema.length > 0 && (
                <div className="content-templates">
                  <OrangeBorderButton
                    width={243}
                    height={40}
                    onClick={handlerOpenListForTemplate}
                  >
                    {!selectedTemplate.length
                      ? 'Template (optional)'
                      : `Added ${selectedTemplate.length} templates`}
                  </OrangeBorderButton>
                  {openListForTemplate && (
                    <div className="dropdown-menu">
                      {currentTemplate.map((item) => (
                        <div className="item">
                          <label>
                            <div className="about">
                              <p>{item.name}</p>
                              <p>{item.template_id}</p>
                            </div>
                            <input
                              type="checkbox"
                              checked={selectedTemplate.includes(
                                item.template_id
                              )}
                              onClick={() =>
                                handleSelectTemplate(item.template_id)
                              }
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {!addedCollections.length === true && (
                <>
                  <div className="content-add">
                    <OrangeButton onClick={() => handleAdd()}>Add</OrangeButton>
                  </div>
                  <div
                    className="content-delete"
                    onClick={() => handleDeleteSelected()}
                  >
                    <img src={ClearIcon} alt="" />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {!addedCollections.length === false && (
          <div className="added-collections">
            <div className="added-collections_list">
              {addedCollections.map((item, i) => (
                <div key={i} className="added-collections_list_item">
                  <div className="collection_name">
                    <div className="collection-image">
                      {item.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${item.img}`}
                          alt=""
                        />
                      ) : (
                        <img src={item.img} alt="" />
                      )}
                    </div>

                    <p>{item.name}</p>
                  </div>
                  <div className="collection_schema">
                    <p>
                      {Array.isArray(item.schema) && item.schema.length > 1
                        ? `[${item.schema.join(', ')}]`
                        : item.schema}
                    </p>
                  </div>
                  <div className="collection_template">
                    <p>
                      {Array.isArray(item.template) && item.template.length > 1
                        ? `[${item.template.join(', ')}]`
                        : item.template}
                    </p>
                  </div>
                  <div
                    className="close"
                    onClick={() => deleteAddedCollection()}
                  >
                    <CloseIconRedSVG />
                  </div>
                </div>
              ))}
            </div>
            <div className="confirmation">
              <OrangeBorderButton onClick={hide}>Confirm</OrangeBorderButton>
            </div>
          </div>
        )}
      </Rodal>
    );
  }

  if (selectedOption === SELL) {
    return (
      <div className={'rodals'}>
        <Rodal
          className={'rodal-1'}
          visible={visible}
          onClose={hide}
          closeMaskOnClick={false}
        >
          <div className="title">
            <h2>Select NFT</h2>
            <p className={'sub-title'}>
              You can use only collection or select by template and schema
            </p>
          </div>
          <div className="content">
            <div className="content-collections">
              <OrangeBorderButton
                onClick={handlerOpenList}
                width={243}
                height={40}
              >
                {!selectedCollectionForSellPool ? (
                  '+ Choose collection'
                ) : (
                  <div className={'coll-block'}>
                    {selectedCollectionForSellPool?.img &&
                    selectedCollectionForSellPool.img?.startsWith('Qm') ? (
                      <img
                        src={`https://atomichub-ipfs.com/ipfs/${selectedCollectionForSellPool?.img}`}
                        alt=""
                      />
                    ) : (
                      <img src={selectedCollectionForSellPool?.img} alt="" />
                    )}
                    {selectedCollectionForSellPool?.collection_name}
                  </div>
                )}
              </OrangeBorderButton>
              {openList && (
                <div className="dropdown-menu">
                  <input
                    type="text"
                    className={'search-collection'}
                    placeholder={'Search collection'}
                    onChange={(e) => setSearchCollectionValue(e.target.value)}
                  />
                  {allUserCollections.map((item, i) => (
                    <div
                      key={i}
                      className="item"
                      onClick={() => handleSelectCollectionForSellPool(item)}
                    >
                      <div className={'about'}>
                        {item.data.img && item.data.img?.startsWith('Qm') ? (
                          <img
                            src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                            alt=""
                          />
                        ) : (
                          <img src={item.data.img} alt="" />
                        )}
                        <p>{item?.collection_name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {selectedCollectionForSellPool && (
              <>
                <div className="content-schema">
                  <OrangeBorderButton
                    onClick={handlerOpenListForSellSchema}
                    width={243}
                    height={40}
                  >
                    {!selectedSchemaForSellPool ? (
                      'Schema (optional)'
                    ) : (
                      <>
                        <p> {selectedSchemaForSellPool}</p>
                        {/*<span>x</span>*/}
                      </>
                    )}
                  </OrangeBorderButton>
                  {openListForSellSchema && (
                    <div className="dropdown-menu">
                      {currentSchemaForSell.map((item) => (
                        <div
                          className="item"
                          onClick={() =>
                            handlerSelectedSchemaForSell(item.schema_name)
                          }
                        >
                          <div className="about">
                            <p>{item.schema_name}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {selectedSchema > 0 && (
                  <div className="content-templates">
                    <OrangeBorderButton
                      width={243}
                      height={40}
                      onClick={handlerOpenListForTemplate}
                    >
                      {!selectedTemplate.length
                        ? 'Template (optional)'
                        : selectedTemplate}
                    </OrangeBorderButton>
                    {openListForTemplate && (
                      <div className="dropdown-menu">
                        {currentTemplate.map((item) => (
                          <div
                            className="item"
                            onClick={() => handleSelectTemplate(item.name)}
                          >
                            <div className={'about'}>
                              <p>{item.name}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <>
                  <div
                    className="content-delete"
                    onClick={() => handleDeleteSelected()}
                  >
                    <img src={ClearIcon} alt="" />
                  </div>
                </>
              </>
            )}
          </div>

          <div className="my-nft">
            <div className="my-nft_title">
              <h3>Selected NFTs: {selectedItemFromSell.length}</h3>
              <OrangeBorderButton
                disabled={selectedItemFromSell.length === 0}
                className={selectedItemFromSell.length === 0 ? 'disabled' : ''}
                onClick={() => hide()}
              >
                Confirm
              </OrangeBorderButton>
            </div>
            <div className="my-nft-list">
              {myNFTs &&
                myNFTs
                  .filter(
                    (item) =>
                      !selectedCollectionForSellPool?.collection_name ||
                      item.collection.collection_name ===
                        selectedCollectionForSellPool?.collection_name
                  )
                  .filter(
                    (item) =>
                      !selectedSchemaForSellPool ||
                      item.schema.schema_name === selectedSchemaForSellPool
                  )
                  .map((item, index) => {
                    const isChecked = selectedItemFromSell.some(
                      (i) => i.asset_id === item.asset_id
                    );
                    return (
                      <div
                        className="my-nft-item"
                        key={index}
                        onClick={() => handlerClickItem(item)}
                      >
                        <div className="checked">
                          <Checkbox checked={isChecked} color="success" />
                        </div>
                        <div className="image">
                          {item.data.img?.startsWith('Qm') ? (
                            <img
                              src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                              alt=""
                            />
                          ) : (
                            <img src={item.data.img} alt="" />
                          )}
                        </div>
                        <div className="collection-block">
                          <p>{item.collection.collection_name}</p>
                        </div>
                        <div className="asset-id">
                          <p>#{item.asset_id}</p>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </Rodal>
      </div>
    );
  }

  if (selectedOption === BOTH) {
    return (
      <Rodal
        className={'rodal-0'}
        visible={visible}
        onClose={hide}
        closeMaskOnClick={false}
      >
        <div className="title">
          <h2>Select NFT</h2>
          <p className={'sub-title'}>
            You can use only collection or select by template and schema
          </p>
        </div>
        <div className="content">
          <div className="content-collections">
            <OrangeBorderButton
              onClick={handlerOpenList}
              width={243}
              height={40}
            >
              {!selectedCollection ? (
                '+ Choose collection'
              ) : (
                <div className={'coll-block'}>
                  {selectedCollection.data.img &&
                  selectedCollection.data.img?.startsWith('Qm') ? (
                    <img
                      src={`https://atomichub-ipfs.com/ipfs/${selectedCollection.data.img}`}
                      alt=""
                    />
                  ) : (
                    <img src={selectedCollection.data.img} alt="" />
                  )}
                  {selectedCollection?.collection_name}
                </div>
              )}
            </OrangeBorderButton>
            {openList && (
              <div className="dropdown-menu">
                <div className="search-block">
                  <input
                    type="text"
                    className={'search-collection'}
                    placeholder={'Search collection'}
                    onChange={(e) => setSearchCollectionValue(e.target.value)}
                  />
                </div>

                {allCollections.map((item, i) => (
                  <div
                    key={i}
                    className="item"
                    onClick={() => handleSelectCollection(item)}
                  >
                    {item.data.img && item.data.img?.startsWith('Qm') ? (
                      <img
                        src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                        alt=""
                      />
                    ) : (
                      <img src={item.data.img} alt="" />
                    )}

                    <div className={'about'}>
                      <p>{item.collection_name}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {selectedCollection && (
            <>
              <div className="content-schema">
                <OrangeBorderButton
                  width={243}
                  height={40}
                  onClick={handlerOpenListForSchema}
                >
                  {!selectedSchema.length
                    ? 'Schema (optional)'
                    : selectedSchema}
                </OrangeBorderButton>
                {openListForSchema && (
                  <div className="dropdown-menu">
                    {currentSchema.map((item) => (
                      <div
                        className="item"
                        onClick={() => handleSelectSchema(item.schema_name)}
                      >
                        <div className="about">
                          <p>{item.schema_name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {selectedSchema.length > 0 && (
                <div className="content-templates">
                  <OrangeBorderButton
                    width={243}
                    height={40}
                    onClick={handlerOpenListForTemplate}
                  >
                    {!selectedTemplate.length
                      ? 'Template (optional)'
                      : `Added ${selectedTemplate.length} templates`}
                  </OrangeBorderButton>
                  {openListForTemplate && (
                    <div className="dropdown-menu">
                      {currentTemplate.map((item) => (
                        <div className="item">
                          <label>
                            <div className="about">
                              <p>{item.name}</p>
                              <p>{item.template_id}</p>
                            </div>
                            <input
                              type="checkbox"
                              checked={selectedTemplate.includes(
                                item.template_id
                              )}
                              onClick={() =>
                                handleSelectTemplate(item.template_id)
                              }
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {!addedCollections.length === true && (
                <>
                  <div className="content-add">
                    <OrangeButton onClick={() => handleAdd()}>Add</OrangeButton>
                  </div>
                  <div
                    className="content-delete"
                    onClick={() => handleDeleteSelected()}
                  >
                    <img src={ClearIcon} alt="" />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {!addedCollections.length === false && (
          <div className="added-collections">
            <div className="added-collections_list">
              {addedCollections.map((item, i) => (
                <div key={i} className="added-collections_list_item">
                  <div className="collection_name">
                    <div className="collection-image">
                      {item.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${item.img}`}
                          alt=""
                        />
                      ) : (
                        <img src={item.img} alt="" />
                      )}
                    </div>

                    <p>{item.name}</p>
                  </div>
                  <div className="collection_schema">
                    <p>
                      {Array.isArray(item.schema) && item.schema.length > 1
                        ? `[${item.schema.join(', ')}]`
                        : item.schema}
                    </p>
                  </div>
                  <div className="collection_template">
                    <p>
                      {Array.isArray(item.template) && item.template.length > 1
                        ? `[${item.template.join(', ')}]`
                        : item.template}
                    </p>
                  </div>
                  <div
                    className="close"
                    onClick={() => deleteAddedCollection()}
                  >
                    <CloseIconRedSVG />
                  </div>
                </div>
              ))}
            </div>
            <div className="confirmation">
              <OrangeBorderButton onClick={hide}>Confirm</OrangeBorderButton>
            </div>
          </div>
        )}
      </Rodal>
    );
  }
}

export default RodalCreatePool;
