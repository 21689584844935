import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WaxIcon from '../../images/wax_logo.png';

import './PoolItem.scss';
import LoaderData from '../LoaderData/LoaderData';

const PoolItem = ({
  filteredSellPools,
  filteredBuyPools,
  filteredTradePools,
  collectionsName,
  checked,
  searchCollection,
  selectedFilterCollection,
  selectedFilterSchema,
}) => {
  const [sellPools, setSellPools] = useState([]);
  const [buyPools, setBuyPools] = useState([]);
  const [tradePools, setTradePools] = useState([]);

  useEffect(() => {
    const sellP = filteredSellPools.filter(
      (pool) => !checked || pool.pool_nfts.length > 0
    );
    const buyP = filteredBuyPools.filter(
      (pool) => !checked || pool.pool_asset !== '0.00000000 WAX'
    );
    // const tradeP = filteredTradePools.filter(pool => !checked || pool.pool_asset !== '0.00000000 WAX');

    setSellPools(sellP);
    setBuyPools(buyP);
    setTradePools(filteredTradePools);
  }, [filteredSellPools, filteredBuyPools, filteredTradePools, checked]);

  const formatPrice = (price) => {
    return parseFloat(price?.split(' ')[0]).toFixed(2);
  };

  return (
    <>
      {!sellPools.length && !buyPools.length && !tradePools.length ? (
        <div className={'loader-data'}>
          <LoaderData />
        </div>
      ) : (
        sellPools
          .concat(buyPools, tradePools)
          .filter(
            (item) =>
              !selectedFilterSchema ||
              !selectedFilterSchema.length ||
              item?.schemas?.includes(...selectedFilterSchema)
          )
          .filter(
            (item) =>
              !searchCollection ||
              item.NFTs_collection?.collection_name.includes(searchCollection)
          )
          .filter(
            (item) =>
              !selectedFilterCollection ||
              selectedFilterCollection === 'all' ||
              item.NFTs_collection?.collection_name.includes(
                selectedFilterCollection
              )
          )
          .map((item, index) => {
            // const deltaType = parseFloat(item?.linear_delta) !== 0 ? <span className={'item-delta'}><p>lin</p><ArrowLin /></span> : parseFloat(item.exponential_delta) !== 0 ? <span className={'item-delta'}><p>exp</p><ArrowExpon /></span> : '';
            const poolType = buyPools?.includes(item)
              ? 'buy'
              : sellPools.includes(item)
              ? 'sell'
              : tradePools?.includes(item)
              ? 'trade'
              : '';
            const itemType =
              poolType === 'buy'
                ? 'Buy'
                : poolType === 'sell'
                ? 'Sell'
                : 'Trade';
            const poolLink =
              poolType === 'buy'
                ? `/collections/${
                    collectionsName
                      ? collectionsName
                      : item?.NFTs_collection?.collection_name
                  }/buy/${item.pool_id}`
                : poolType === 'sell'
                ? `/collections/${
                    collectionsName
                      ? collectionsName
                      : item?.NFTs_collection?.collection_name
                  }/sell/${item.pool_id}`
                : poolType === 'trade'
                ? `/collections/${
                    collectionsName
                      ? collectionsName
                      : item?.NFTs_collection?.collection_name
                  }/trade/${item.pool_id}`
                : '';

            return (
              <Link key={index} to={poolLink}>
                <div className="item">
                  <div className="item-wrapper">
                    <div className="top">
                      <div className="collection_name">
                        <div className="collection-image-s">
                          {item.NFTs_collection?.img?.startsWith('Qm') ? (
                            <img
                              src={`https://atomichub-ipfs.com/ipfs/${item.NFTs_collection?.img}`}
                              alt=""
                            />
                          ) : (
                            <img src={item.NFTs_collection?.img} alt="" />
                          )}
                        </div>
                        {item?.NFTs_collection?.collection_name}
                      </div>
                      <div className="type-pool">
                        <p className={`item-type ${poolType}`}>{itemType}</p>
                      </div>
                    </div>
                    {itemType === 'Buy' && (
                      <div className="nfts-content">
                        {item.NFTs_collection?.img?.startsWith('Qm') ? (
                          <img
                            src={`https://atomichub-ipfs.com/ipfs/${item.NFTs_collection?.img}`}
                            alt=""
                          />
                        ) : (
                          <img src={item.NFTs_collection?.img} alt="" />
                        )}
                      </div>
                    )}
                    {itemType === 'Sell' && (
                      <div className={'nfts-content-card'}>
                        {item.pool_nfts.slice(0, 3).map((nft, index) => (
                          <div key={index} className="nfts-content-card_img">
                            {nft?.data?.img?.startsWith('Qm') ? (
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${nft?.data?.img}`}
                                alt=""
                              />
                            ) : (
                              <img src={nft?.data?.img} alt="" />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {itemType === 'Trade' && (
                      <div className={'nfts-content-card'}>
                        {item.pool_nfts.slice(0, 3).map((nft, index) => (
                          <div key={index} className="nfts-content-card_img">
                            {nft?.data?.img?.startsWith('Qm') ? (
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${nft?.data?.img}`}
                                alt=""
                              />
                            ) : (
                              <img src={nft?.data?.img} alt="" />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="container">
                      <div className="container-wrapper">
                        <div className="price">
                          <div className="price-left">
                            <p className={`price-left-name ${poolType}`}>
                              {itemType === 'Trade' ? (
                                <p>Sell price</p>
                              ) : (
                                <p>{itemType} price</p>
                              )}
                            </p>
                            <div className="price-left_top_amount">
                              <img src={WaxIcon} alt="" />
                              <p className="price-left-amount">
                                {formatPrice(item?.floor_price)}
                              </p>
                            </div>
                          </div>
                          <div className="price-right">
                            {itemType === 'Trade' && (
                              <>
                                <p className={`price-right-name`}>
                                  <p>Buy price</p>
                                </p>
                                <div className="price-right_top_amount">
                                  <img src={WaxIcon} alt="" />
                                  <p className="price-right-amount">
                                    {formatPrice(item?.pool_asset)}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="delta-balance">
                          <div className="balance">
                            <p className="balance-p">Balance</p>
                            <div className="balance-wax">
                              {itemType === 'Buy' && (
                                <>
                                  <img src={WaxIcon} alt="" />
                                  <p className="balance-amount">
                                    {formatPrice(item?.pool_asset)}
                                  </p>
                                </>
                              )}
                              {itemType === 'Sell' && (
                                <p className="balance-amount">
                                  {item.pool_nfts ? item.pool_nfts.length : 0}
                                </p>
                              )}

                              {itemType === 'Trade' && (
                                <p className="balance-amount">
                                  <img src={WaxIcon} alt="" />
                                  <>
                                    <p className="balance-amount">
                                      {formatPrice(item?.pool_asset)}
                                    </p>
                                    <p> | </p>
                                    <p>
                                      {item.pool_nfts
                                        ? item.pool_nfts.length
                                        : 0}
                                    </p>
                                  </>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="delta">
                            <p className="delta-p">
                              <p>Owner</p>
                            </p>
                            <p className="delta-amount">{item?.pool_owner}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })
      )}
    </>
  );
};

PoolItem.propTypes = {
  filteredSellPools: PropTypes.array.isRequired,
  filteredBuyPools: PropTypes.array.isRequired,
  // collectionsName: PropTypes.string.isRequired,
};

export default PoolItem;
