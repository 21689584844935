import React, { useState, useEffect, useContext } from 'react';
import Switcher from '../../components/Switcher/Switcher';
import OrangeBorderButton from '../../components/OrangeBorderButton/OrangeBorderButton';
import PoolItem from '../../components/PoolItem/PoolItem';
import {
  getBuyPoolsByCollections,
  getSellPoolsByCollections,
  getTradePoolsByCollections,
} from '../../services/wax.services';
import { UALContext } from 'ual-reactjs-renderer';
import './Pool.scss';
import FillterSideBar from '../../components/FilterSideBar/FilterSideBar';
import LoaderData from '../../components/LoaderData/LoaderData';

const Pool = () => {
  const { activeUser } = useContext(UALContext);
  const [checked, setChecked] = useState(true);
  const [checkedEmpty, setCheckedEmpty] = useState(true);
  const [sellPools, setSellPools] = useState([]);
  const [buyPools, setBuyPools] = useState([]);
  const [tradePools, setTradePools] = useState([]);

  const [searchCollection, setSearchCollection] = useState('');
  const [selectedFilterCollection, setSelectedFilterCollection] =
    useState('all');
  const [selectedFilterSchema, setSelectedFilterSchema] = useState([]);
  const [selectedPoolType, setSelectedPoolType] = useState('');

  useEffect(() => {
    const fetchPools = async () => {
      setTimeout(async () => {
        try {
          const [sellPools, buyPools, tradePools] = await Promise.all([
            getSellPoolsByCollections(),
            getBuyPoolsByCollections(),
            getTradePoolsByCollections(),
          ]);

          const filteredSellPools = filterPools(sellPools);
          const filteredBuyPools = filterPools(buyPools);
          const filteredTradePools = filterPools(tradePools);

          setSellPools(filteredSellPools);
          setBuyPools(filteredBuyPools);
          setTradePools(filteredTradePools);
        } catch (error) {
          console.error(error);
        }
      }, 1500);
    };

    const filterPools = (pools) => {
      return pools.filter(
        (pool) =>
          pool.pool_owner === activeUser?.accountName &&
          pool.token_contract === 'eosio.token' &&
          pool.floor_price.includes('WAX')
      );
    };

    fetchPools();
  }, [activeUser]);

  return (
    <div className={'poole-section'}>
      <div className="poole-section-title">
        <h2>Pools Overview</h2>
        <p>
          View all of the pools that {activeUser?.accountName} provides
          liquidity for
        </p>
        <Switcher
          checked={checkedEmpty}
          setChecked={setCheckedEmpty}
          label={checkedEmpty ? 'Hiding empty pool' : 'Showing empty pools'}
        />
        <OrangeBorderButton
          classNameForMain={'poole-section-title_create'}
          width={179}
          height={44}
          link={'/create'}
        >
          + Create New Pool
        </OrangeBorderButton>
      </div>

      {sellPools.length > 0 || buyPools.length > 0 ? (
        <div className="poole-section-content">
          <FillterSideBar
            sellPools={sellPools}
            buyPools={buyPools}
            checked={checked}
            setChecked={setChecked}
            setSearchCollection={setSearchCollection}
            selectedFilterCollection={selectedFilterCollection}
            setSelectedFilterCollection={setSelectedFilterCollection}
            setSelectedFilterSchema={setSelectedFilterSchema}
            selectedPoolType={selectedPoolType}
            setSelectedPoolType={setSelectedPoolType}
          />
          <div className="poll-list">
            <PoolItem
              filteredSellPools={
                selectedPoolType === 'nft'
                  ? sellPools
                  : selectedPoolType === 'token'
                  ? []
                  : selectedPoolType === 'trade'
                  ? []
                  : sellPools
              }
              filteredBuyPools={
                selectedPoolType === 'token'
                  ? buyPools
                  : selectedPoolType === 'nft'
                  ? []
                  : selectedPoolType === 'trade'
                  ? []
                  : buyPools
              }
              filteredTradePools={
                selectedPoolType === 'trade'
                  ? tradePools
                  : selectedPoolType === 'nft' || selectedPoolType === 'token'
                  ? []
                  : tradePools
              }
              checked={checkedEmpty}
              searchCollection={searchCollection}
              selectedFilterCollection={selectedFilterCollection}
              selectedFilterSchema={selectedFilterSchema}
              selectedPoolType={selectedPoolType}
            />
          </div>
        </div>
      ) : (
        <div className={'loader-data'}>
          <LoaderData />
        </div>
      )}
    </div>
  );
};

export default Pool;
